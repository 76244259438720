import React ,{ useRef } from 'react';
import { LangingPageImageUrl } from '../../../Config';
// import { useFormik } from 'formik';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import { CAMPAIGN_ID_LANDINGPAGE } from '../../../Config';
import Form from '../../website/pages/Form';

const IntegratedSolarRoofComp = () => {

        const CurrentYear = new Date().getFullYear();

        const keyFeatures = useRef(null);
        const scrollToKeyFeatures = () => {
            keyFeatures.current.scrollIntoView({ behavior: 'smooth' });
        };

        const certificate = useRef(null);
        const scrollToKeyCertificates = () => {
            certificate.current.scrollIntoView({ behavior: 'smooth' });
        };

        const featuredOn = useRef(null);
        const scrollToKeyFeaturedOn = () => {
            featuredOn.current.scrollIntoView({ behavior: 'smooth' });
        };

        const gallery = useRef(null);
        const scrollToKeyGallery = () => {
            gallery.current.scrollIntoView({ behavior: 'smooth' });
        };

        const getAQuoteFrom = useRef(null);
        const scrollToKeygetQuoteBtn = () => {
            getAQuoteFrom.current.scrollIntoView({ behavior: 'smooth' });
        };

    return (
        <>
            <button className="btn d-md-flex book-now desktop open-datepicker-popup" onClick={scrollToKeygetQuoteBtn} title="Get A Quote"> Get A Quote</button>
            <header className="header">
                <div className="container-fluid">
                    <div className="row py-0 py-sm-0 py-md-3 py-lg-4 py-xl-4 px-0 px-sm-0 px-md-3 px-lg-4 px-xl-4">
                        <div className="col-12 col-lg-3 d-none d-lg-flex">
                            <div className="logo d-flex align-items-center">
                                <Link to="/integrated-solar-roof"><img src={LangingPageImageUrl+'images/atum-logo.png'} alt="Logo" /></Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="row">
                                <div className="col-12">
                                    <div className="top-menu">
                                        <div className="top-menu-list">
                                            <nav className="navbar navbar-expand-lg navbar-dark bg-light py-lg-0">
                                                <div className="container-fluid p-0">
                                                    <Link className="navbar-brand logo d-lg-none" to="/integrated-solar-roof"><img src={LangingPageImageUrl+'images/atum-logo.png'} alt="Mobile Logo" /></Link>
                                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        <span className="navbar-toggler-icon"></span>
                                                    </button>
                                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                        <ul className="navbar-nav ms-auto mb-0 mb-lg-0 pt-3 pt-lg-0">
                                                            <li className="nav-item">
                                                                <button className="border-0 nav-link active px-3 py-2 mb-1 py-lg-0 mb-lg-0 bg-lg-transparent mobile-responsive-width cursor-pointer" aria-current="page" onClick={scrollToKeyFeatures}><span>Key features</span></button>
                                                            </li>
                                                            <li className="nav-item">
                                                                <button className="border-0 nav-link px-3 py-2 mb-1 py-lg-0 mb-lg-0 bg-lg-transparent mobile-responsive-width cursor-pointer" onClick={scrollToKeyCertificates}><span>Certifications</span></button>
                                                            </li>
                                                            <li className="nav-item">
                                                                <button className="border-0 nav-link px-3 py-2 mb-1 py-lg-0 mb-lg-0 bg-lg-transparent mobile-responsive-width cursor-pointer" onClick={scrollToKeyFeaturedOn}><span>Featured On</span></button>
                                                            </li>
                                                            <li className="nav-item">
                                                                <button className="border-0 nav-link px-3 py-2 mb-1 py-lg-0 mb-lg-0 bg-lg-transparent mobile-responsive-width cursor-pointer" onClick={scrollToKeyGallery}><span>Gallery</span></button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="landingISR-slider">
                <div id="landingISRHomeSlidercarousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators landingISR-downbtn">
                        <button type="button" data-bs-target="#landingISRHomeSlidercarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#landingISRHomeSlidercarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#landingISRHomeSlidercarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={LangingPageImageUrl+'images/slider/solar-slider-a.jpg'} className="d-block w-100" alt="Home Slider" />
                        </div>
                        <div className="carousel-item">
                            <img src={LangingPageImageUrl+'images/slider/solar-slider-a.jpg'} className="d-block w-100" alt="Home Slider" />
                        </div>
                        <div className="carousel-item">
                            <img src={LangingPageImageUrl+'images/slider/solar-slider-a.jpg'} className="d-block w-100" alt="Home Slider" />
                        </div>
                    </div>
                    <div className="bannerForm-responsive" ref={getAQuoteFrom}>
                        <div className="slider-content-form">
                            <h4 className="form-heading text-white text-center">Get A Quote</h4>
                            <Form campaignId={CAMPAIGN_ID_LANDINGPAGE} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="landingISR-about">
                <div className="container ">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-content py-4">
                                <h2 className="section-heading text-center">About</h2>
                                <p className="section-desp px-lg-5 mx-lg-5">With ATUM, we bring you the luxury of smart solar roofing. Seamlessly integrated with your
                                    building to
                                    <span className="content-span"> generate 40% more power </span>
                                    than ordinary solar, ATUM is the first of its kind in the world! The sustainable roof is made using GreenPro eco-labelled materials that can
                                    <span className="content-span"> last over 50 years </span>
                                    and can be a great alternative to metal sheets, sloped RCC or clay tiles. With no power bills to pay and savings from day one of installation,
                                    ATUM rewards you with a payback period of just 2.5 years.
                                </p>
                                <p className="section-desp">
                                    A roof so smart, ATUM lets you track your clean energy and savings on your smartphone in real time.
                                    Shielding industrial, commercial and residential buildings from harsh weathers, leaks and fire damage like no other, ATUM is a safe roof that keeps your cash flow intact.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={keyFeatures} className="landingISR-key-features">
                <div className="container ">
                    <div className="row box-bg">
                        <div className="col-12">
                            <div className="section-content py-4">
                                <h2 className="section-heading text-center">Key Features</h2>
                                <div className="features-list p-4">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/atum_is_patented.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Atum is Patented</h5>
                                            <p className="feature-desc text-white px-2">The very first integrated solar roof</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/class_a_ rated.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Class A Fire Rated</h5>
                                            <p className="feature-desc text-white px-2">Resists fire damage for over 2.5 hrs</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/hurrican_proof.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Hurricane Proof</h5>
                                            <p className="feature-desc text-white px-2">Can withstand wind speeds of 255 kmph</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/themermal.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Thermal Resistance</h5>
                                            <p className="feature-desc text-white px-2">Keeps your interiors up to 3<sup>o</sup>C cooler</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/long_life.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Long Life</h5>
                                            <p className="feature-desc text-white px-2">It has a minimum lifespan of 25 years</p>
                                        </div>
                                        <div className="col-md-1 d-none d-md-flex"></div>
                                        <div className="col-md-1 d-none d-md-flex"></div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/more_power.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">More Power</h5>
                                            <p className="feature-desc text-white px-2">Can generate up to 40% more power</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/sturdy.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Sturdy</h5>
                                            <p className="feature-desc text-white px-2">Fire, water, radiation and shock resistant</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/payback.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Payback Period</h5>
                                            <p className="feature-desc text-white px-2">As low as 2.5 years for complete payback</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/load_bearing.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Load Bearing</h5>
                                            <p className="feature-desc text-white px-2">A live load capacity of 300 kg per sqft</p>
                                        </div>
                                        <div className="col-6 col-md-2 text-center px-0 pt-2">
                                            <img src={LangingPageImageUrl+'images/features/leak_proof.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                            <h5 className="feature-title py-1">Leak-proof</h5>
                                            <p className="feature-desc text-white px-2">Guaranteed 100% leak-proof roof</p>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="patented">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <img src={LangingPageImageUrl+'images/product.png'} width="40%" alt="20 Years Printed" />
                        </div>
                    </div>
                </div>
            </section>
            <section ref={certificate} className="landingISR-certificates">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-content py-4">
                                <h2 className="section-heading text-center">Certifications</h2>
                                <div className="row mt-3 py-4 align-items-center">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <img src={LangingPageImageUrl+'images/certificates/loan_master_logo.png'} alt="Certification" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <img src={LangingPageImageUrl+'images/certificates/bis-certification-service.png'} alt="Certification" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <img src={LangingPageImageUrl+'images/certificates/ul-logo.png'} alt="Certification" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <img src={LangingPageImageUrl+'images/certificates/tuv.png'} alt="Certification" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={featuredOn} className="landingISR-featuredOnSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-content pt-4">
                                <h2 className="section-heading text-center">Featured On</h2>
                                <div className="row mt-5">
                                    <div className="col-lg-2 col-md-3  col-4 mb-3 text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/entrepreneur-india.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/forbes-India.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/better-india.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-4 mb-3  text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/telangana-today.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/the-hindu.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-2 col-md-3  col-4  mb-3 text-center">
                                        <img src={LangingPageImageUrl+'images/featuredon/the-new-indian-express.png'} alt="Featured On Images" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={gallery} className="landingISR-gallerySection">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-content py-4">
                                <h2 className="section-heading text-center">Gallery</h2>
                                <div id="landingISRGalleryCarousel" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row">
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/1.jpg'} alt="First slide" />
                                                </div>
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/2.jpg'} alt="First slide" />
                                                </div>
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/3.jpg'} alt="First slide" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/4.png'} alt="First slide" />
                                                </div>
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/2.jpg'} alt="First slide" />
                                                </div>
                                                <div className="col-4">
                                                    <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/3.jpg'} alt="First slide" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#landingISRGalleryCarousel" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#landingISRGalleryCarousel" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-content text-center pt-4">
                                <Link to="/integrated-solar-roof"><img src={LangingPageImageUrl+'images/atum-by-visakawhite.png'} width="100" alt="Footer ATUM" /></Link>
                                <ul className="d-flex justify-content-center align-items-center m-0 p-0 my-2">
                                    <li className="m-1"><a href="https://www.facebook.com/atumroof/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="var(--common_white)" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg></a></li>
                                    <li className="m-1"><a href="https://www.instagram.com/atumroof/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="var(--common_white)" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg></a></li>
                                    <li className="m-1"><a href="https://twitter.com/atumroof" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="var(--common_white)" viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" /></svg></a></li>
                                    <li className="m-1"><a href="https://www.linkedin.com/company/atumsolar/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="var(--common_white)" viewBox="0 0 448 512"><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg></a></li>
                                    <li className="m-1 me-0"><a href="https://www.youtube.com/c/atumsolarroof" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="var(--common_white)" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" /></svg></a></li>
                                </ul>
                                <p className="text-white"><span>&#169;</span> {CurrentYear} Visaka Industries Ltd. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegratedSolarRoofComp